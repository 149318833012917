<div class="co4-wrapper">
    <div class="suggested-resources">
        <div class="container-fluid top">
            <div class="container-xxxl">
                <div class="row pt-5rem">
                    <div class="col">
                        <app-bar-header widthInRem="25" [textHeader]="textHeader" theme="maroon"></app-bar-header>
                    </div>
                </div>
                <div class="row summary mb-3 pb-3 mb-lg-5 pb-lg-0">
                    <div class="col p-0 fs-md-18">
                        <p class="mb-0">{{ summary }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid bottom overflow-hidden position-relative" *ngIf="tabs?.length">
            <button-detail-list-larger [tabs]="tabs" [hideTabs]="true"></button-detail-list-larger>
        </div>
    </div>
</div>
