import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'redirect-parent',
    templateUrl: './redirect-parent.component.html',
    styleUrls: ['./redirect-parent.component.scss'],
})
export class RedirectParentComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit(): void {
        this.router.navigate([this.router.url.substring(0, this.router.url.lastIndexOf('/'))]);
    }
}
