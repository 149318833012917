<div class="single-ka-left-only-lightgray--wrapper">
    <div class="container-fluid">
        <div class="container-xxxl pt-5">
            <app-bar-header [textHeader]="article.title" theme="blue"></app-bar-header>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container-xxxl">
            <div class="row summary pb-3">
                <div class="col col-lg-6 p-0 fs-md-18">
                    <p class="mb-0 ps-3" [innerHtml]="article.bodyText"></p>
                </div>
            </div>
        </div>
    </div>
</div>
