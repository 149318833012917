<div class="container-fluid pt-5">
    <div class="container-xxxl bg-white">
        <div class="row">
            <!-- For md, lg and xxl screen -->
            <div class="d-none d-md-block col-6 left" #leftSide>
                <app-bar-header textHeader="Your Diagnostic Team" [theme]="theme"> </app-bar-header>

                <header-h3 [theme]="theme" [textHeader]="medTeam?.title"></header-h3>

                <div class="mt-3"></div>

                <div class="fs-md-18 mb-5" [innerHtml]="medTeam?.bodyText"></div>

                <div class="img-container">
                    <img class="mb-3" [src]="imgUrl" alt="imgAltText" (load)="calcRightSideHeight()" />
                </div>
            </div>
            <div class="d-none d-md-block col-6 right-side" *ngIf="!isNull">
                <div class="title">{{ this.recommended.title }}</div>
                <div [ngClass]="{ contain6: !showMore }" class="parent-div" #rightSide>
                    <your-medical-team-accordion [articles]="articlesMatchingTag"></your-medical-team-accordion>
                </div>
            </div>
            <div class="d-none d-md-block col-6 right-side" *ngIf="isNull">
                <div class="title">{{ this.allOthers.title }}</div>
                <div class="fs-md-18 mb-5" [innerHtml]="allOthers?.bodyText"></div>
            </div>

            <!-- For small screen -->
            <div class="d-block d-md-none col-12 left" #leftSide>
                <app-bar-header textHeader="Your Diagnostic Team" [theme]="theme"> </app-bar-header>

                <header-h3 [theme]="theme" [textHeader]="medTeam?.title"></header-h3>

                <div class="mt-5"></div>

                <clamper class="fs-6 fs-md-18" [maxLines]="8" [text]="medTeam?.bodyText"></clamper>

                <div class="img-container">
                    <img class="mb-3" [src]="imgUrl" alt="imgAltText" (load)="calcRightSideHeight()" />
                </div>

                <div *ngIf="!isNull">
                    <div class="title">{{ this.recommended.title }}</div>
                    <div [ngClass]="{ contain6: !showMore }" class="parent-div" #rightSide>
                        <your-medical-team-accordion [articles]="articlesMatchingTag"></your-medical-team-accordion>
                    </div>
                </div>

                <div *ngIf="isNull">
                    <div class="title">{{ this.allOthers.title }}</div>
                    <clamper class="fs-6 fs-md-18" [maxLines]="8" [text]="allOthers?.bodyText"></clamper>
                </div>
            </div>
        </div>
    </div>
</div>
