<div class="container-fluid py-4 bg-lightblue contact-us-contact-center">
    <div class="container-xxxl py-lg-4">
        <!-- for xl,xxl-screens -->
        <div class="row d-none d-lg-none d-xl-flex d-xxl-flex d-md-none d-sm-none">
            <div class="col-5">
                <h3 class="text-blue border-start border-blue border-5 ps-4 mb-4 fs-md-1">{{ mainTitle }}</h3>
                <div class="fs-md-18" [innerHTML]="mainBody"></div>
            </div>

            <div class="col-1"></div>
            <div class="col-1 border-start border-midgray"></div>

            <div class="col-5">
                <div class="row">
                    <div class="col-6 pb-4">
                        <img class="icon-75" [src]="imgPath + phoneKa.images[0].url + '-100.webp'" alt="" />
                        <h5 class="text-blue fs-22">{{ phoneTitle }}</h5>
                        <div class="phone-body" [innerHTML]="phoneBody"></div>
                        <p class="text-blue fw-bold mt-4 mb-1 fs-18">{{ tollTitle }}</p>
                        <div class="d-flex">
                            <div class="tel-link">
                                <h3 class="text-gard-green pt-1 text-center">
                                    <span [innerHTML]="tollBody"></span>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-6 pb-4">
                        <img class="icon-75" [src]="imgPath + contactGard.images[0].url + '-100.webp'" alt="" />
                        <h5 class="text-blue fs-22">{{ formTitle }}</h5>
                        <div class="mb-4" [innerHTML]="formBody"></div>
                        <div class="">
                            <a link class="btn btn-green btn-arrow px-4" [href]="buttonUrl" target="_blank" rel="noopener noreferrer"
                                >{{ buttonTitle }} <i class="icon-gard-arrow-right ms-1"></i
                            ></a>
                        </div>
                    </div>
                </div>

                <div class="row border-top border-2">
                    <div class="col-12 py-4 pt-0 mt-5 pt-2">
                        <div class="d-flex mb-4">
                            <img class="icon-50" [src]="imgPath + submitYourOrg.images[0].url + '-100.webp'" alt="" />
                            <h5 class="text-blue fs-22 mt-4 ms-3">{{ requestFormTitle }}</h5>
                        </div>
                        <div class="mb-4" [innerHTML]="requestFormBody"></div>
                    </div>
                    <div class="col-6"></div>
                    <div class="col-6">
                        <a link class="btn btn-green btn-arrow px-4" [href]="requestButtonUrl" target="_blank" rel="noopener noreferrer">
                            {{ requestButtonTitle }} <i class="icon-gard-arrow-right ms-1"></i
                        ></a>
                    </div>
                </div>
            </div>
        </div>

        <!-- for lg -screens -->
        <div class="row d-none d-lg-flex d-xl-none d-xxl-none d-md-none d-sm-none">
            <div class="col-5">
                <h3 class="text-blue border-start border-blue border-5 ps-4 mb-4 fs-md-1">{{ mainTitle }}</h3>
                <div class="fs-md-18" [innerHTML]="mainBody"></div>
            </div>

            <div class="col-1"></div>
            <div class="col-1 border-start border-midgray"></div>

            <div class="col-5">
                <div class="col-12">
                    <div class="d-flex mb-4">
                        <img class="icon-75" [src]="imgPath + phoneKa.images[0].url + '-100.webp'" alt="" />
                        <h5 class="text-blue fs-22 mt-3 ms-2">{{ phoneTitle }}</h5>
                    </div>
                    <div class="" [innerHTML]="phoneBody"></div>
                    <p class="text-blue fw-bold mt-4 mb-1 fs-18">{{ tollTitle }}</p>
                    <div class="d-flex">
                        <div class="tel-link">
                            <a [href]="'tel:' + tollBody">
                                <h3 class="text-gard-green pt-1 text-center">
                                    <span [innerHTML]="tollBody"></span>
                                </h3>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-12 mt-5">
                    <div class="py-4 pt-0 mt-5 pt-5">
                        <div class="d-flex mb-4">
                            <img class="icon-75" [src]="imgPath + contactGard.images[0].url + '-100.webp'" alt="" />
                            <h5 class="text-blue fs-22 mt-3 ms-2">{{ formTitle }}</h5>
                        </div>
                        <div class="mb-4" [innerHTML]="formBody"></div>
                        <div class="">
                            <a link class="btn btn-green btn-arrow px-4" [href]="buttonUrl" target="_blank" rel="noopener noreferrer"
                                >{{ buttonTitle }} <i class="icon-gard-arrow-right ms-1"></i
                            ></a>
                        </div>
                    </div>
                </div>

                <div class="col-12 mt-5">
                    <div class="py-4 pt-0 mt-5 pt-5">
                        <div class="d-flex mb-4">
                            <img class="icon-50" [src]="imgPath + submitYourOrg.images[0].url + '-100.webp'" alt="" />
                            <h5 class="text-blue fs-22 mt-3 pt-1 text-nowrap ms-2">{{ requestFormTitle }}</h5>
                        </div>
                        <div class="mb-4" [innerHTML]="requestFormBody"></div>
                        <div class="">
                            <a link class="btn btn-green btn-arrow px-4" [href]="requestButtonUrl" target="_blank" rel="noopener noreferrer">
                                {{ requestButtonTitle }} <i class="icon-gard-arrow-right ms-1"></i
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- for md-screen -->
        <div class="row d-none d-sm-none d-md-block d-lg-none">
            <div class="col-12">
                <h3 class="text-blue border-start border-blue border-5 ps-4 mb-4 fs-1">{{ mainTitle }}</h3>
                <div class="fs-18" [innerHTML]="mainBody"></div>
            </div>

            <div class="border-bottom border-light mt-5 mb-3"></div>

            <div class="col-12">
                <div class="row justify-content-center pb-5">
                    <div class="col-6 mt-5 ps-5">
                        <div class="d-flex mt-3">
                            <div class="icon-holder border-blue border rounded-circle mb-4">
                                <i class="icon icon-gard-contact text-blue"></i>
                            </div>
                            <h5 class="text-blue fs-22 mt-3 ms-2">{{ phoneTitle }}</h5>
                        </div>
                        <div class="" [innerHTML]="phoneBody"></div>
                        <p class="text-blue fw-bold mt-4 mb-1 fs-18">{{ tollTitle }}</p>
                        <div class="d-flex">
                            <div class="tel-link">
                                <a [href]="'tel:' + tollBody">
                                    <h3 class="text-gard-green pt-1 text-center">
                                        <span [innerHTML]="tollBody"></span>
                                    </h3>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="border-start col-1 mt-3 border-light d-block"></div>

                    <div class="col-5 mt-5 pe-5">
                        <div class="d-flex mt-3 mb-4">
                            <div class="icon-holder border-blue border rounded-circle"><i class="icon icon-gard-phone text-blue"></i></div>
                            <h5 class="text-blue fs-22 mt-3 ms-2">{{ formTitle }}</h5>
                        </div>
                        <div class="mb-4" [innerHTML]="formBody"></div>
                        <div class="">
                            <a link class="btn btn-green btn-arrow px-4" [href]="buttonUrl" target="_blank" rel="noopener noreferrer"
                                >{{ buttonTitle }} <i class="icon-gard-arrow-right ms-1"></i
                            ></a>
                        </div>
                    </div>
                </div>

                <div class="row border-top border-2">
                    <div class="col-11 ps-5 pt-5">
                        <div class="d-flex mt-3 mb-4">
                            <div class="icon-holder"><img class="icon-gard-form" [src]="requestFormIcon" /></div>
                            <h5 class="text-blue fs-22 mt-4 ps-3">{{ requestFormTitle }}</h5>
                        </div>
                        <div class="mt-4 mb-4" [innerHTML]="requestFormBody"></div>
                    </div>
                    <div class="col-7"></div>
                    <div class="col-5">
                        <a link class="btn btn-green btn-arrow px-4" [href]="requestButtonUrl" target="_blank" rel="noopener noreferrer">
                            {{ requestButtonTitle }} <i class="icon-gard-arrow-right ms-1"></i
                        ></a>
                    </div>
                </div>
            </div>
        </div>

        <!-- for xs,sm-screens -->
        <div class="row d-block d-md-none d-lg-none">
            <div class="col-12">
                <h3 class="text-blue border-start border-blue border-5 ps-4 mb-4 fs-1">{{ mainTitle }}</h3>
                <div class="fs-18" [innerHTML]="mainBody"></div>
            </div>

            <div class="col-12">
                <div class="col-12 mt-5 pt-5 border-top border-2">
                    <div class="d-flex mt-3">
                        <div class="icon-holder border-blue border rounded-circle mb-4">
                            <i class="icon icon-gard-contact text-blue"></i>
                        </div>
                        <h5 class="text-blue fs-18 mt-3 ms-2">{{ phoneTitle }}</h5>
                    </div>

                    <div class="" [innerHTML]="phoneBody"></div>
                    <p class="text-blue fw-bold mt-4 mb-1 fs-18">{{ tollTitle }}</p>
                    <div class="d-flex">
                        <div class="tel-link">
                            <a [href]="'tel:' + tollBody">
                                <h3 class="text-gard-green pt-1 text-center">
                                    <span [innerHTML]="tollBody"></span>
                                </h3>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 py-4 mt-3">
                <div class="d-flex mt-3">
                    <div class="icon-holder border-blue border rounded-circle mb-4"><i class="icon icon-gard-phone text-blue"></i></div>
                    <h5 class="text-blue fs-18 mt-3 ms-2">{{ formTitle }}</h5>
                </div>

                <div class="mb-4" [innerHTML]="formBody"></div>
                <div class="">
                    <a link class="btn btn-green btn-arrow px-4" [href]="buttonUrl" target="_blank" rel="noopener noreferrer"
                        >{{ buttonTitle }} <i class="icon-gard-arrow-right ms-1"></i
                    ></a>
                </div>
            </div>

            <div class="col-12 py-4 mt-3">
                <div class="d-flex mt-3 mb-4">
                    <div class="icon-holder"><img class="icon-gard-form" [src]="requestFormIcon" /></div>
                    <h5 class="text-blue fs-18 mt-3 pt-1 ms-3">{{ requestFormTitle }}</h5>
                </div>

                <div class="mb-4" [innerHTML]="requestFormBody"></div>
                <div class="">
                    <a link class="btn btn-green btn-arrow px-4" [href]="requestButtonUrl" target="_blank" rel="noopener noreferrer"
                        >{{ requestButtonTitle }} <i class="icon-gard-arrow-right ms-1"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</div>
