<top-navigation></top-navigation>
<main-banner [disease]="disease"></main-banner>

<disease-at-a-glance [id]="sections.disease.id"></disease-at-a-glance>

<!-- What Causes This Disease? -->
<causes [id]="sections.causes.id" theme="maroon"></causes>

<!-- Can This Disease Be Passed Down From Parent to Child? -->
<inheritance theme="maroon"></inheritance>

<!-- At What Age Does This Disease Typically Appear? -->
<age-at-onset theme="blue"></age-at-onset>

<!-- Symptoms -->
<symptoms-section theme="white"></symptoms-section>

<!-- Diagnostic Journey -->
<diagnostic-journey [id]="sections.diagnosticJourney.id"></diagnostic-journey>

<!-- Your Diagnostic Team -->
<your-medical-team theme="maroon"></your-medical-team>

<rare-disease-expert></rare-disease-expert>

<line-break-shadow-below></line-break-shadow-below>

<!-- A5 Find Your Community -->
<advocacy-and-support-groups [id]="sections.findYourCommunity.id"></advocacy-and-support-groups>

<!-- A6 PAG Accounts -->
<patient-organizations-accounts></patient-organizations-accounts>

<!-- A7 -->
<participating-in-clinical-studies [id]="sections.clinicalStudies.id"></participating-in-clinical-studies>

<!-- A8 -->
<clinical-studies></clinical-studies>

<!-- A9 -->
<clinical-trials-contact-us></clinical-trials-contact-us>

<div class="mb-5"></div>

<app-bottom-sources-date-information></app-bottom-sources-date-information>
<app-footer></app-footer>
