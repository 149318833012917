import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Article } from 'shared/types';
import { Page, Section, SubSection } from 'shared/location-enums';
import { ArticleService } from '../../../../services/article.service';
import { PATHS } from 'shared/paths';
import { Language } from 'shared/salesforce-types';

@Component({
    selector: 'improving-access',
    templateUrl: './improving-access.component.html',
    styleUrls: ['./improving-access.component.scss'],
})
export class ImprovingAccessComponent {
    articleUnderstandIntro: Article;
    articleExploreDiseasePagesButtonText: Article;
    articleTabAAboutDisease: Article;
    articleTabBDiagnosisTreatment: Article;
    articleTabCLivingDisease: Article;
    articleTabDResearch: Article;
    diseasePagesUrl: string;
    public imgPath = PATHS.articleImages;

    constructor(articleService: ArticleService, route: ActivatedRoute) {
        const pageLanguage = route?.snapshot?.data?.language || Language.En;
        this.diseasePagesUrl = '/' + PATHS[pageLanguage].diseases;

        this.articleUnderstandIntro = articleService.getOne(Page.about, Section.gardEfforts, SubSection.intro);

        this.articleExploreDiseasePagesButtonText = articleService.getOne(Page.about, Section.gardEfforts, SubSection.browseDiseaseButton);

        this.articleTabAAboutDisease = articleService.getOne(Page.about, Section.gardEfforts, SubSection.routineUpdates);

        this.articleTabCLivingDisease = articleService.getOne(Page.about, Section.gardEfforts, SubSection.guidance);

        this.articleTabBDiagnosisTreatment = articleService.getOne(Page.about, Section.gardEfforts, SubSection.healthInformation);

        this.articleTabDResearch = articleService.getOne(Page.about, Section.gardEfforts, SubSection.collaboration);
    }
}
