import { AfterViewInit, ElementRef, QueryList } from '@angular/core';
import { Component, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ABOUT_SECTIONS, DISEASE_SECTIONS } from '../../../../../shared/constants';

@Component({
    selector: 'disease-tabs',
    templateUrl: './disease-tabs.component.html',
    styleUrls: ['./disease-tabs.component.scss'],
})
export class DiseaseTabsComponent implements AfterViewInit {
    @ViewChild('nav') nav: ElementRef<HTMLDivElement>;
    @ViewChildren('navLink') navLinks: QueryList<ElementRef<HTMLDivElement>>;

    public links = [];
    public upperLinks = [];
    public isTabsOpen = false;
    public activeIndex = -1;

    private readonly navItemOffset = 20;

    constructor(route: ActivatedRoute) {
        const path = route.snapshot.routeConfig.path;
        const prefix = path.length ? '../' : '';
        const sections = Object.values(DISEASE_SECTIONS);
        const sectionsDisplay = sections.filter(section => section.show);
        // const index = sections.findIndex(v => v.link === path);
        const currentSection = sectionsDisplay[0];

        this.links = Object.values(DISEASE_SECTIONS.about.sections || {}) || [];
        this.upperLinks = sectionsDisplay.map(({ link, description }) => ({ link: prefix + link, description }));
        this.activeIndex = sectionsDisplay.findIndex(v => v.link === path);
    }

    ngAfterViewInit() {
        const activeElement = this.navLinks.get(this.activeIndex)?.nativeElement;

        if (!activeElement) {
            return;
        }

        const left = activeElement.getBoundingClientRect().x - this.navItemOffset;

        this.nav.nativeElement.scrollTo({ left });
    }

    public jumpToSection(event: MouseEvent, id: string): void {
        event.stopPropagation();
        event.preventDefault();
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' }); // scroll to a particular element
        }
        this.isTabsOpen = false;
    }

    public toggleTabs() {
        this.isTabsOpen = !this.isTabsOpen;
    }
}
