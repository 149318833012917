<!-- TITLE -->
<div *ngIf="!isNull; else nullCase" class="mb-4 mb-lg-5 row">
    <div class="col col-lg-6">
        <header-h2 [theme]="theme" [textHeader]="mainArticle?.title"></header-h2>
        <p class="mb-2 fs-md-18 text-link" [innerHtml]="mainArticle?.bodyText"></p>
        <p class="fs-md-18" *ngIf="subArticle" [innerHtml]="subArticle?.bodyText"></p>
    </div>
</div>

<ng-template #nullCase>
    <header-h2 [theme]="theme" [textHeader]="nullArticle?.title"></header-h2>
    <p [innerHtml]="nullArticle?.bodyText"></p>
</ng-template>

<!-- DESKTOP -->
<div class="tabs-container d-none d-lg-flex" *ngIf="articles?.length > 0 && !isNull">
    <!-- TABS -->
    <div class="tabs">
        <ul role="tablist">
            <li *ngFor="let article of articles; let i = index" class="fs-5">
                <button class="d-flex justify-content-center align-items-center" [ngClass]="{ active: i === activeTabIndex }" (click)="switchTab(i)" role="tab" type="button">
                    {{ article.title }}
                </button>
            </li>
        </ul>
    </div>

    <!-- TAB CONTENT -->
    <div class="content">
        <h1 class="text-blue fs-22">{{ articles[activeTabIndex].title }}</h1>
        <div class="row mt-4 scroll-area" [style.max-height]="scrollHeight + 'px'">
            <div class="left-side col-12 col-xl-5" *ngIf="imageUrl">
                <img [style.max-height]="imgMaxHeight + 'px'" class="mb-3 mb-xl-0" [src]="imageUrl" [alt]="imageAlt" />
            </div>
            <div
                class="right-side col-12 text-link"
                [ngClass]="{
                    'col-xl-7': imageUrl
                }"
                embeddedHTML
                [innerHTML]="articles[activeTabIndex].bodyText"
            ></div>
        </div>
    </div>
</div>

<!-- MOBILE -->
<div class="d-flex d-lg-none flex-column mobile-tabs-container pt-2">
    <!-- TABS -->
    <div class="tabs" *ngIf="!mobileArticle; else mobileContent">
        <ul role="tablist">
            <li *ngFor="let article of articles; let i = index" class="fs-6">
                <button class="d-flex justify-content-center align-items-center" (click)="mobileSwitchTab(article)" role="tab" type="button">
                    {{ article.title }}
                </button>
            </li>
        </ul>
    </div>

    <!-- CONTENT -->
    <ng-template #mobileContent>
        <div class="panel-fullscreen bg-white p-4">
            <div class="mb-4">
                <button class="btn text-green m-0 p-0 d-flex align-items-center" type="button" (click)="mobileSwitchTab(null)">
                    <img src="assets/back-arrow.svg" class="fs-18 me-2" />
                    <span class="fs-18 align-text-bottom">Back</span>
                </button>
            </div>

            <h1 class="text-blue fw-bold fs-5 pt-1 mb-4">{{ mobileArticle.title }}</h1>

            <div class="d-flex justify-content-center pb-2" *ngIf="imageUrl">
                <img class="w-100" [src]="imageUrl" [alt]="imageAlt" />
            </div>

            <div embeddedHTML [innerHtml]="mobileArticle.bodyText" class="mt-4 fs-6 text-link"></div>
        </div>
    </ng-template>
</div>
