import { Component } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { Page, Section, SubSection } from 'shared/location-enums';
import { Article } from 'shared/types';

@Component({
    selector: 'caregiver-resources',
    templateUrl: './caregiver-resources.component.html',
    styleUrls: ['./caregiver-resources.component.scss'],
})
export class CaregiverResourcesComponent {
    parentKA: Article;
    selfParentKA: Article;
    selfResourceList: Article[];
    othersParentKA: Article;
    otherResourceList: Article[];
    caregiverResources: Article;

    constructor(articleService: ArticleService) {
        /* PARENT ARTICLE */
        this.parentKA = articleService.getOne(Page.livingWith, Section.caregiverResources, SubSection.parent, undefined, undefined, false);

        /* SELF ARTICLES */
        this.selfResourceList = articleService.getArticles(Page.livingWith, Section.caregiverResources, SubSection.yourself, undefined, undefined, false);
        this.selfParentKA = this.selfResourceList.shift(); // First resource is parent

        /* OTHERS ARTICLES */
        this.otherResourceList = articleService.getArticles(Page.livingWith, Section.caregiverResources, SubSection.others, undefined, undefined, false);
        this.othersParentKA = this.otherResourceList.shift(); // First resource is parent

        this.caregiverResources = articleService.getOne(Page.livingWith, Section.caregiverResources, SubSection.sectionHeader);
    }
}
