import { AfterViewInit, Component } from '@angular/core';
import { EXTERNAL_POPUP_TEXT_EN, ABOUT_SECTIONS } from 'shared/constants';
import { Article, Disease } from 'shared/types';
import { ArticleService } from 'src/app/services/article.service';
import { DiseaseService } from 'src/app/services/disease.service';
import { Page, Section } from 'shared/location-enums';

@Component({
    selector: 'resources-page',
    templateUrl: './resources-page.component.html',
    styleUrls: ['./resources-page.component.scss'],
})
export class ResourcesPageComponent implements AfterViewInit {
    public sections = ABOUT_SECTIONS;
    public disease: Disease;
    public patientResources: Article;

    constructor(diseaseService: DiseaseService, private articleService: ArticleService) {
        this.disease = diseaseService.disease;
        this.patientResources = this.articleService.getOne(Page.resources, Section.header);
    }

    ngAfterViewInit() {
        const links = Object.values(document.getElementsByTagName('a')).filter(link => !link.href.includes('.gov') && !link.baseURI.includes(link.hostname));

        links.forEach(linkEl => {
            const href = linkEl.href;
            linkEl.addEventListener('click', (ev: MouseEvent) => this.onOutsideRedirect(ev, href));
        });
    }

    onOutsideRedirect(ev: MouseEvent, link: string) {
        ev.preventDefault();
        ev.stopPropagation();
        if (window.confirm(EXTERNAL_POPUP_TEXT_EN)) {
            const tab = window.open(link, '_blank');
            tab.opener = null;
        }
    }
}
