<div class="informed-decisions-wrapper container-fluid bg-lightblue pt-5">
    <div class="container-xxxl pt-lg-4 pb-lg-3">
        <div class="row">
            <div class="col col-lg-7">
                <app-bar-header [textHeader]="resourcesforYourHealthCareDecisions.title" theme="blue"></app-bar-header>
                <div class="fs-6 fs-md-18" [innerText]="parentKA.bodyText"></div>
            </div>
        </div>
        <div class="row">
            <!-- Multi Tab Goes Here -->
            <div class="col">
                <app-multitab [tabs]="tabs" [hideTabs]="true"></app-multitab>
            </div>
        </div>
    </div>
</div>
