<top-navigation></top-navigation>

<main-banner [title]="headerArticle.title" [description]="headerArticle.bodyText"></main-banner>

<!-- GARD's Mission -->
<about-description></about-description>

<!-- What Is a Rare Disease? | Which Rare Diseases Are Included In GARD? -->
<!-- <ab3-faq></ab3-faq> -->

<improving-access></improving-access>

<!-- GARD Website FAQs -->
<about-gard-website-faq></about-gard-website-faq>

<app-footer></app-footer>
