<top-navigation></top-navigation>

<main-banner [title]="patientResources.title" [description]="patientResources.bodyText"></main-banner>

<!-- <living-with-intro [id]="sections.livingIntro.id"></living-with-intro> -->

<!-- Patient Resources -->
<suggested-resources [id]="sections.patientResources.id"></suggested-resources>

<!-- Caregiver Resources -->
<caregiver-resources [id]="sections.caregiverResources.id"></caregiver-resources>

<!-- Resources for Your Health Care Decisions -->
<living-with-informed-decisions [id]="sections.decisions.id"></living-with-informed-decisions>

<app-footer></app-footer>
