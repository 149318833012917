/* eslint-disable prefer-rest-params */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Section as SECTIONS, SubSection as SUBSECTIONS, Page as TABS } from '../../../shared/location-enums';
import { TAG_CATEGORIES } from '../../../shared/constants';
import { PATHS } from '../../../shared/paths';
import { Account, Disease, Tag } from '../../../shared/types';
import { SalesforceAccountPayload, TagAccountAssociation } from '../../../shared/salesforce-types';
import { map, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ErrorLogService } from './error-log.service';
import { HELPFUL_LINKS, PatientAdvocacyGroup } from '../components/common/organizations/organizations-interfaces';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    private accounts: Account[] = [];

    constructor(private http: HttpClient, private errorLogService: ErrorLogService) {}

    static convertSalesforceDataToAccount(account: SalesforceAccountPayload): Account {
        const epidemiology: string[] = [];
        (account.tagAccountAssociationList || []).forEach((tagAccountAssociation: TagAccountAssociation) => {
            if ((tagAccountAssociation.Tag_Category__c || '').split(';').includes(TAG_CATEGORIES.epidemiology)) {
                epidemiology.push(tagAccountAssociation.Tag_Name__c);
            }
        });

        // New TAGs code
        const tagDiseaseCategory: string[] = [];
        const tagAccount: string[] = [];
        const tagSpecialist: string[] = [];
        const tagCause: string[] = [];

        (account.tagAccountAssociationList || []).forEach((tagAccountAssociation: TagAccountAssociation) => {
            const categories = (tagAccountAssociation.Tag_Category__c || '').split(';').filter(emptyCheck => !!emptyCheck);
            // if (categories.length > 0) debugger;

            if (categories.includes(TAG_CATEGORIES.diseaseCategory)) {
                tagDiseaseCategory.push(tagAccountAssociation.Tag_Name__c);
            }
            if (categories.includes(TAG_CATEGORIES.account)) {
                tagAccount.push(tagAccountAssociation.Tag_Name__c);
            }
            if (categories.includes(TAG_CATEGORIES.specialist)) {
                tagSpecialist.push(tagAccountAssociation.Tag_Name__c);
            }
            if (categories.includes(TAG_CATEGORIES.cause)) {
                tagCause.push(tagAccountAssociation.Tag_Name__c);
            }
        });

        return {
            id: account.acct.Id,
            name: account.acct.Name,
            country: account.acct.Country__c,
            website: account.acct.Website,
            additionalPostedInfo: account.acct.Additional_Posted_Information__c,
            tab: account.acct.Web_Page_Tab__c,
            section: account.acct.Web_Page_Section__c,
            subSection: account.acct.Web_Page_Sub_Section__c,
            order: parseInt(account.acct.Web_Page_Order__c, 10),
            expertDirectoryUrl: account.acct.Expert_Directory_URL__c,
            patientRegistryUrl: account.acct.Patient_Registry_URL__c,
            contentDistributionList: account.contentDistributionList || [],
            epidemiology,
            tagDiseaseCategory,
            tagAccount,
            tagSpecialist,
            tagCause,
        } as Account;
    }

    fetch(): Observable<Account[]> {
        return this.httpGet().pipe(
            take(1),
            map(as => as.map(AccountService.convertSalesforceDataToAccount)),
            tap(accounts => {
                this.accounts = accounts.sort((a, b) => (a.order || 0) - (b.order || 0));
            }),
        );
    }

    getOne(tab?: TABS, section?: SECTIONS, subsection?: SUBSECTIONS): Account {
        // pull original account list, using filters, if provided
        const allAccounts: Account[] = this.getAccounts(tab, section, subsection);

        if (allAccounts.length === 1) {
            // Good return
            return allAccounts[0];
        } else {
            // Error cases
            const criteria: string[] = [];

            ['tab', 'section', 'subsection', 'allPages'].forEach((key, index) => {
                if (arguments[index] !== undefined) {
                    criteria.push(`${key}: ${arguments[index]}`);
                }
            });

            let errorMessage = '';
            if (allAccounts.length === 0) {
                errorMessage = `AccountService.getOne()\nNo account matching criteria:\n${criteria.join('\n')}\n`;
            } else {
                errorMessage = `AccountService.getOne()\nMultiple (${allAccounts.length}) accounts matching criteria:\n${criteria.join('\n')}\n`;
            }

            this.errorLogService.log('Account', errorMessage);

            return undefined;
        }
    }

    getAccounts(tab?: TABS, section?: SECTIONS, subsection?: SUBSECTIONS): Account[] {
        return this.accounts.filter(
            account =>
                (tab === undefined || account.tab === tab) &&
                (section === undefined || account.section === section) &&
                (subsection === undefined || account.subSection === subsection),
        );
    }

    httpGet(): Observable<SalesforceAccountPayload[]> {
        return this.http.get<SalesforceAccountPayload[]>(PATHS.accountApi);
    }

    availableLocalOrganizations(currentDisease: Disease, includeDiseaseSpecific: boolean, includeTagMatches: boolean, includeRare: boolean): PatientAdvocacyGroup[] {
        // !!!
        // !!! NOT YET USED !!!
        // !!!

        // loop over all accounts
        const LocalOrganizations: PatientAdvocacyGroup[] = [];
        // on/off switch
        if (includeTagMatches) {
            // usually only one "Account" per disease
            currentDisease.tagAccount.forEach((tagAccount: string) => {
                // Old? no longer working?
                // const accountsPAG = this.accounts.filter((acct: Account) => acct.recordType === ACCOUNT_RECORD_TYPES.LocalOrganization);
                // filter for this tagAccount only
                const accountsWithDiseaseCategory = this.accounts.filter((acct: Account) => acct.tagAccount.includes(tagAccount));
                accountsWithDiseaseCategory.forEach((account: Account) => {
                    // PAG links column
                    const helpfulLinks: string[] = [];
                    if (account.expertDirectoryUrl) {
                        helpfulLinks.push(HELPFUL_LINKS.listOfExperts);
                    }
                    if (account.patientRegistryUrl) {
                        helpfulLinks.push(HELPFUL_LINKS.researchRegistry);
                    }
                    // PAG Order
                    const linkOrder = -1 * helpfulLinks.length;
                    // Has disease tag matching with current disease
                    const diseaseCategoriesWithDescriptions: Tag[] = currentDisease.diseaseCategoriesWithDescriptions;
                    const diseaseCategoriesWithDescriptionsMatching = diseaseCategoriesWithDescriptions.filter((tag: Tag) => tag.name === tagAccount);
                    const dzCategory = diseaseCategoriesWithDescriptionsMatching[0];
                    LocalOrganizations.push({
                        ...account,
                        url: account.website,
                        helpfulLinks,
                        linkOrder,
                        whoTheyServe: dzCategory.nameCurated,
                        serveOrder: 2,
                    } as PatientAdvocacyGroup);
                });
            });
        }
        // Accounts not tagged with disease category
        const accountsNoDiseaseCategory = this.accounts.filter((acct: Account) => acct.tagDiseaseCategory.length === 0);
        accountsNoDiseaseCategory.forEach((account: Account) => {
            // PAG links column
            const helpfulLinks: string[] = [];
            if (account.expertDirectoryUrl) {
                helpfulLinks.push(HELPFUL_LINKS.listOfExperts);
            }
            if (account.patientRegistryUrl) {
                helpfulLinks.push(HELPFUL_LINKS.researchRegistry);
            }
            // PAG Order
            const linkOrder = -1 * helpfulLinks.length;
            // Disease Specific
            if (includeDiseaseSpecific) {
                if (currentDisease.organizationSupported.find(organization => organization.name === account.name)) {
                    LocalOrganizations.push({
                        ...account,
                        url: account.website,
                        helpfulLinks,
                        linkOrder,
                        whoTheyServe: currentDisease.name,
                        serveOrder: 1,
                    } as PatientAdvocacyGroup);
                }
            }
            // Rare tag is present
            if (includeRare) {
                if ((account.epidemiology || []).includes(TAG_CATEGORIES.rare)) {
                    LocalOrganizations.push({ ...account, url: account.website, helpfulLinks, linkOrder, whoTheyServe: 'Rare Diseases', serveOrder: 3 } as PatientAdvocacyGroup);
                }
            }
        });
        // sort
        LocalOrganizations.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)) // Alphabetize
            .sort((a, b) => (a.serveOrder > b.serveOrder ? 1 : b.serveOrder > a.serveOrder ? -1 : 0)); // Sort by whoTheyServe
        return LocalOrganizations;
    }
}
