import { Component, Input } from '@angular/core';

@Component({
    selector: 'header-h2',
    templateUrl: './header-h2.component.html',
    styleUrls: ['./header-h2.component.scss'],
})
export class HeaderH2Component {
    @Input() theme: 'maroon' | 'blue' | 'white' = undefined;
    @Input() textHeader = '';
    @Input() fontWeight: number = 500;
}
