import { Organization } from 'shared/salesforce-types';

export interface PatientAdvocacyGroup extends Partial<Organization> {
    helpfulLinks?: string[];
    whoTheyServe: string;
    serveOrder: number;
    linkOrder: number;
}

export const SALESFORCE_ACCOUNT_TITLE = {
    specialist: 'Accounts - Specialists',
    research: 'Accounts - Research',
    information: 'Accounts - Information',
};

export const HELPFUL_LINKS = {
    listOfExperts: 'List of Experts',
    researchRegistry: 'Research Registry',
};
