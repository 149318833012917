import { Component, ElementRef, Renderer2, AfterViewInit, ViewChild, Input } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { DiseaseService } from 'src/app/services/disease.service';
import { Article, Disease, Image } from 'shared/types';
import { Page, Section, SubSection } from 'shared/location-enums';
import { PATHS } from 'shared/paths';

@Component({
    selector: 'your-medical-team',
    templateUrl: './your-medical-team.component.html',
    styleUrls: ['./your-medical-team.component.scss'],
})
export class YourMedicalTeamComponent implements AfterViewInit {
    public medTeam: Article;
    public recommended: Article;
    public allOthers: Article;
    public showMore = false;
    public isNull = false;
    public allArticles: Article[] = [];
    public articlesMatchingTag: Article[] = [];
    public articlesMatchingTagInverse: Article[] = [];
    public articleImagesPath = PATHS.articleImages;
    public imgUrl: string = '';
    @Input() theme: 'maroon' | 'blue' | 'white' = undefined;

    @ViewChild('rightSide') rightSide: ElementRef<HTMLDivElement>;
    @ViewChild('leftSide') leftSide: ElementRef<HTMLDivElement>;

    constructor(diseaseService: DiseaseService, articleService: ArticleService, private renderer: Renderer2) {
        this.allOthers = articleService.getArticles(Page.diagnosis, Section.medicalTeam, SubSection.otherSpecialist)[0];

        // Specialist section
        // get all articles
        this.allArticles = articleService.getArticles(Page.diagnosis, Section.medicalTeam, SubSection.specialists);

        // filter out main article, insure images
        this.allArticles = YourMedicalTeamComponent.postFetchProcessArticles(this.allArticles);

        // filter by disease tags
        this.articlesMatchingTag = YourMedicalTeamComponent.getArticles(this.allArticles, diseaseService.disease);

        // compute if null
        this.isNull = this.articlesMatchingTag.length === 0;

        // Sort Alpha by title
        this.articlesMatchingTag.sort((a, b) => a.title.localeCompare(b.title, 'en', { sensitivity: 'base' }));

        // filter by disease tags - inverse
        this.articlesMatchingTagInverse = YourMedicalTeamComponent.getArticlesInverse(this.allArticles, this.articlesMatchingTag);

        // Sort Alpha by title
        this.articlesMatchingTagInverse.sort((a, b) => a.title.localeCompare(b.title, 'en', { sensitivity: 'base' }));

        // This for the body
        this.medTeam = articleService.getArticles(Page.diagnosis, Section.medicalTeam, SubSection.parent, undefined, undefined, this.isNull)[0];
        if (this.medTeam) {
            this.medTeam.bodyText = this.medTeam.bodyText.replace('${DiseaseName}', diseaseService.disease.name);
            if (this.medTeam.images.length) {
                this.imgUrl = PATHS.articleImages + this.medTeam.images[0].url + '-800.webp';
            }
        }

        // this is for the title of the list of specialists, if null, don't show
        this.recommended = articleService.getArticles(Page.diagnosis, Section.medicalTeam, SubSection.taggedSpecialist)[0];
        if (this.recommended) {
            this.recommended.title = this.recommended.title.replace('${DiseaseName}', diseaseService.disease.name);
        }
    }

    static getArticles(allArticles: Article[], disease: Disease): Article[] {
        // sort by order, undefined after items with defined order
        allArticles.sort((a, b) => (a.order !== undefined ? a.order : Infinity) - (b.order !== undefined ? b.order : Infinity));

        // filter by disease tags
        return allArticles.filter(art => art.allPages || (art.tags && art.tags.some(tag => disease.tagsSpecialist.indexOf(tag) > -1)));
    }

    static getArticlesInverse(allArticles: Article[], articlesMatchingTag: Article[]): Article[] {
        const retArticles: Article[] = [];
        const articlesMatchingTagIds = articlesMatchingTag.map(a => a.id);
        allArticles.forEach((article: Article) => {
            if (!articlesMatchingTagIds.includes(article.id)) {
                retArticles.push(article);
            }
        });

        return retArticles;
    }

    static postFetchProcessArticles(allArticles: Article[]): Article[] {
        // filter out main article
        allArticles.map(article => {
            // eslint-disable-next-line no-prototype-builtins
            if (!article.hasOwnProperty('images')) {
                article.images = [];
            }

            if (article.images.length === 0) {
                article.images.push({ url: '', altText: '' } as Image);
            }

            return article;
        });

        return allArticles;
    }

    static isNull(articleService: ArticleService, disease: Disease): boolean {
        return this.getArticles(articleService.getArticles(Page.diagnosis, Section.medicalTeam), disease).length <= 1;
    }

    ngAfterViewInit() {
        this.calcRightSideHeight();
    }

    calcRightSideHeight() {
        const divElementLeft = this.leftSide.nativeElement;
        if (divElementLeft) {
            const leftHeight = divElementLeft.clientHeight;
            const divElementRight = this.rightSide.nativeElement;
            if (divElementRight) {
                this.renderer.setStyle(divElementRight, 'max-height', leftHeight - 143 + 'px');
            }
        }
    }

    expand() {
        this.showMore = true;
    }
}
