<top-navigation></top-navigation>
<div class="container-fluid py-5 mx-0 text-center main-container" *ngIf="!isSearchResultPage">
    <div class="row py-5">
        <div class="col">
            <div class="col-md-6 col-lg-4 col-12 m-auto">
                <h3 class="text-maroon mb-5">
                    We recently launched the new GARD website and are still developing specific pages. This page is currently unavailable. If you need help finding information
                    about a disease, please <a href="/contact" class="text-link">Contact Us.</a>
                </h3>
                <h3 class="text-maroon">
                    Recientemente lanzamos el nuevo sitio web de GARD y todavía estamos desarrollando páginas específicas. Esta página no está disponible. Si necesita ayuda para
                    encontrar información sobre una enfermedad, por favor <a href="espanol/contacte-gard" class="text-link">Contáctenos.</a>
                </h3>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
