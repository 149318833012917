<div class="container-fluid">
    <div class="container-xxxl disease-page-top-element-padding">
        <div class="d-flex row pt-5">
            <div class="col-12 col-lg-6 left-side align-items-center">
                <app-bar-header textHeader="Find Your Community" theme="blue"></app-bar-header>
                <div *ngIf="article.displayTitle" class="title">{{ article.title }}</div>
                <p [innerHtml]="article.bodyText"></p>
            </div>

            <div class="d-none d-lg-flex col-lg-6 right-side">
                <img class="mb-3 rounded-8" [src]="imgsrc" [alt]="imgAltText" />
            </div>
            <div class="col-12 d-flex d-lg-none mobile-img">
                <img class="mb-3 rounded-8" [src]="imgsrc" [alt]="imgAltText" />
            </div>
        </div>
    </div>
</div>

<div class="white-chevron"></div>
