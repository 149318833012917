import { Component } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { Page as P, Section as S } from 'shared/location-enums';
import { Article } from 'shared/types';
import { PATHS } from 'shared/paths';

@Component({
    selector: 'about-description',
    templateUrl: './about-description.component.html',
    styleUrls: ['./about-description.component.scss'],
})
export class AboutDescriptionComponent {
    article: Article;
    public articleImagesPath = PATHS.articleImages;
    imageUrl: string;
    constructor(articleService: ArticleService) {
        this.article = articleService.getOne(P.about, S.gardDescription);

        this.imageUrl = this.articleImagesPath + this.article.images[0]?.url + '-700.webp';
    }
}
