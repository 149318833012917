import { Account, Tag, Disease } from 'shared/types';
import { TAG_CATEGORIES } from 'shared/constants';
import { HELPFUL_LINKS, PatientAdvocacyGroup } from './organizations-interfaces';

export const availablePatientAdvocacyGroups = (
    allAccounts: Account[],
    currentDisease: Disease,
    includeDiseaseSpecific: boolean,
    includeTagMatches: boolean,
    includeRare: boolean,
) =>
    allAccounts
        .map((account: Account) => {
            const { id, expertDirectoryUrl, patientRegistryUrl, country, website } = account;
            const helpfulLinks: string[] = [];
            if (expertDirectoryUrl) {
                helpfulLinks.push(HELPFUL_LINKS.listOfExperts);
            }
            if (patientRegistryUrl) {
                helpfulLinks.push(HELPFUL_LINKS.researchRegistry);
            }
            const linkOrder = -1 * helpfulLinks.length;

            // Has disease tag matching with current disease
            if (includeTagMatches) {
                if (account.tagDiseaseCategory && account.tagDiseaseCategory.length > 0) {
                    const currentDiseaseCategoryNamesMatchingAccount: string[] = [];
                    account.tagDiseaseCategory.forEach((diseaseCategory: string) => {
                        if (currentDisease.tagDiseaseCategory.includes(diseaseCategory)) {
                            currentDiseaseCategoryNamesMatchingAccount.push(diseaseCategory);
                        }
                    });

                    if (currentDiseaseCategoryNamesMatchingAccount.length) {
                        let matchingDiseaseCategory: string = '';

                        matchingDiseaseCategory = currentDiseaseCategoryNamesMatchingAccount[0];

                        if (currentDiseaseCategoryNamesMatchingAccount.length > 1) {
                            console.error('Multiple Category matches:', currentDiseaseCategoryNamesMatchingAccount.join());
                        }

                        const dzCategory: Tag = currentDisease.diseaseCategoriesWithDescriptions.filter((category: Tag) => category.name === matchingDiseaseCategory)[0];

                        if (matchingDiseaseCategory) {
                            return {
                                id,
                                name: account.name,
                                url: website,
                                helpfulLinks,
                                expertDirectoryUrl,
                                patientRegistryUrl,
                                country,
                                linkOrder,
                                whoTheyServe: dzCategory.nameCurated,
                                serveOrder: 2,
                            } as PatientAdvocacyGroup;
                        }
                    }
                }
            }

            // Disease Specific
            if (includeDiseaseSpecific) {
                if (currentDisease.organizationSupported.find(organization => organization.name === account.name)) {
                    return {
                        id,
                        name: account.name,
                        url: website,
                        helpfulLinks,
                        expertDirectoryUrl,
                        patientRegistryUrl,
                        country,
                        linkOrder,
                        whoTheyServe: currentDisease.name,
                        serveOrder: 1,
                    } as PatientAdvocacyGroup;
                }
            }

            // Rare tag is present
            if (includeRare) {
                if ((account.epidemiology || []).includes(TAG_CATEGORIES.rare)) {
                    return {
                        id,
                        name: account.name,
                        url: website,
                        helpfulLinks,
                        expertDirectoryUrl,
                        patientRegistryUrl,
                        country,
                        linkOrder,
                        whoTheyServe: 'Rare Diseases',
                        serveOrder: 3,
                    } as PatientAdvocacyGroup;
                }
            }

            // Null if none of the criteria is met
            return null;
        })
        .filter(acct => !!acct) // Filter out null values
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)) // Alphabetize
        .sort((a, b) => (a.serveOrder > b.serveOrder ? 1 : b.serveOrder > a.serveOrder ? -1 : 0)); // Sort by whoTheyServe
