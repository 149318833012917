<div class="about-research-intro-wrapper container-fluid pt-lg-5">
    <div class="container-xxxl pt-4 mt-3 mt-lg-0">
        <app-bar-header [textHeader]="mainKA.title" theme="maroon"></app-bar-header>
        <p class="description fs-6 fs-lg-18 mb-lg-5" [innerHTML]="mainKA.bodyText"></p>

        <div>
            <img [src]="imgUrl" [alt]="mainKA.images[0]?.altText" />
        </div>
    </div>
</div>
